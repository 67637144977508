import React, { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, Input } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import UploadConceptsModal from "./uploadConceptsModal"
import { PassageState, insertPassagesAction, passageSelector } from "../../hasura/slices/passage"
import { QueryName } from "../../hasura/queryNames"
import { curriculumSelector, CurriculumState, fetchPedagoguesAction } from "../../hasura/slices/curriculum"
import { fetchUserAction, userSelector, UserState } from "../../hasura/slices/user"
import { imagesQueueFor, passagesQueueFor, pluralize } from "../../lib/helpers"
import { insertConceptsAction } from "../../hasura/slices/concept"

export default function AdminHome() {
  const dispatch = useDispatch()

  const { accessToken, user, isPedagogue }: UserState = useSelector(userSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)
  const { isQuerying }: PassageState = useSelector(passageSelector)

  const [displayUploadConceptsModal, setDisplayUploadConceptsModal] = useState(false)
  const [displayUploadPassagesModal, setDisplayUploadPassagesModal] = useState(false)
  const [passagesToUpload, setPassagesToUpload] = useState<string>("")

  const conceptQueue = (user?.concepts_queue_denormalized || "").split(",").filter((s) => s)
  const imageQueue = imagesQueueFor(user)
  const likedPassages = passagesQueueFor(user)
  const passagesToUploadSplit = passagesToUpload.split("\n").filter((s) => s)

  /*
    Effects
  */

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchPedagoguesAction(accessToken))
    dispatch(fetchUserAction(accessToken, user.id))
  }, [accessToken])

  useEffect(() => {
    if (!displayUploadPassagesModal) setPassagesToUpload("")
  }, [displayUploadPassagesModal])

  /*
    Methods
  */

  const uploadPassages = async () => {
    if (!curriculum) return

    await dispatch(insertPassagesAction(accessToken!, passagesToUploadSplit, user!.id, likedPassages, curriculum.id))
    setDisplayUploadPassagesModal(false)
  }

  const uploadConcepts = (concepts: string[], newConcepts: string[], pedagogueId?: string) => {
    if (!accessToken || !user || !curriculum) return

    dispatch(insertConceptsAction(accessToken, concepts, newConcepts, pedagogueId || user.id, curriculum.id))
    setDisplayUploadConceptsModal(false)
  }

  if (!curriculum) return null

  return (
    <div style={{ maxWidth: "1100px" }} className="min-vh-100 m-auto default-width-padding">
      {displayUploadConceptsModal && <UploadConceptsModal close={() => setDisplayUploadConceptsModal(false)} upload={uploadConcepts} />}

      <Modal size="lg" fade={false} centered isOpen={displayUploadPassagesModal} toggle={() => setDisplayUploadPassagesModal(false)}>
        <ModalBody className="text-center min-height-200px flex-center flex-column p-4">
          <p className="text-l bold">Upload Passages</p>

          <Input
            autoFocus
            className="min-height-300px"
            type="textarea"
            value={passagesToUpload}
            onChange={(e: any) => setPassagesToUpload(e.target.value)}
          />
        </ModalBody>

        <ModalFooter className="align-items-center flex-column">
          <Button
            className="min-width-200px height-50px flex-center"
            color="success"
            disabled={!passagesToUploadSplit.length || isQuerying[QueryName.InsertPassages]}
            onClick={uploadPassages}
            size="lg"
          >
            Upload{passagesToUploadSplit.length > 0 && ` ${pluralize("passage", passagesToUploadSplit.length)}`}
          </Button>

          <p onClick={() => setDisplayUploadPassagesModal(false)} className="text-link text-muted">
            Cancel
          </p>
        </ModalFooter>
      </Modal>

      <div className="mt-4 d-flex align-items-start justify-content-between">
        <div>
          <div className="d-flex align-items-start">
            <h1 className="garamond text-xxl m-0">Home</h1>
          </div>
        </div>
      </div>

      <div className="my-3 p-4 border rounded">
        <p className="text-l m-0">
          🌱 <span className="bold mr-1">Step 1</span> Upload
        </p>

        <p className="text-xs text--gray7">Upload concepts and passages to your curriculum.</p>

        <div className="d-flex gap-20px">
          <Button disabled={isPedagogue} className="width-150px" color="primary" onClick={() => setDisplayUploadConceptsModal(true)}>
            Upload concepts
          </Button>

          <Button disabled={isPedagogue} className="width-150px" color="primary" onClick={() => setDisplayUploadPassagesModal(true)}>
            Upload passages
          </Button>
        </div>
      </div>

      <div className="my-3 p-4 border rounded">
        <p className="text-l m-0">
          🎨 <span className="bold mr-1">Step 2</span> Enrich
        </p>

        <p className="text-xs text--gray7">Enrich the concepts, images, and passages in your curriculum.</p>

        <div className="d-flex gap-20px">
          {conceptQueue.length > 0 && (
            <div className="text-center">
              <Button className="width-150px" color="primary" onClick={() => navigate(`/concept?values=${conceptQueue.join()}`)}>
                Enrich concepts
              </Button>
              <p className="text-xxs text--gray7 m-0">{conceptQueue.length} total</p>
            </div>
          )}

          {likedPassages.length > 0 && (
            <div className="text-center">
              <Button className="width-150px" color="primary" onClick={() => navigate(`/tagging?ids=${likedPassages.join()}`)}>
                Enrich passages
              </Button>
              <p className="text-xxs text--gray7 m-0">{likedPassages.length} total</p>
            </div>
          )}

          {imageQueue.length > 0 && (
            <div className="text-center">
              <Button outline className="width-150px" color="primary" onClick={() => navigate(`/image?ids=${imageQueue.join()}`)}>
                Enrich images
              </Button>
              <p className="text-xxs text--gray7 m-0">{imageQueue.length} total</p>
            </div>
          )}

          {Object.values(user?.other_queues || {}).some((v: any) => v.length) && (
            <div className="ml-3">
              {Object.keys(user!.other_queues).map((id) => {
                const ids = user!.other_queues[id]

                return ids.length ? (
                  <Button outline className="width-150px" color="primary" size="sm" onClick={() => navigate(`/tagging?ids=${ids.join()}&r=${id}`)}>
                    {id} ({ids.length})
                  </Button>
                ) : null
              })}
            </div>
          )}
        </div>
      </div>

      {/* <div className="my-3 p-4 border rounded">
        <p className="text-l m-0">
          🎮 <span className="bold mr-1">Step 3</span> Test
        </p>

        <p className="text-xs text--gray7">Gameify your curriculum and test the result.</p>

        <div className="d-flex gap-20px">
          <Button
            disabled={!curriculum || curriculum.id === CONFIG.CORE_CURRICULUM_ID || clickedGenerateCurriculum}
            outline
            className="width-150px"
            color="primary"
            onClick={handleGenerate}
          >
            Generate {clickedGenerateCurriculum ? <Spinner size="sm" /> : null}
          </Button>

          <Button className="width-150px" color="success" onClick={() => dispatch(fetchRandomSequenceAction(curriculum?.id))}>
            Play Random
          </Button>
        </div>
      </div> */}
    </div>
  )
}

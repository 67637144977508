import React from "react"
import { useSelector } from "react-redux"

import AdminHome from "../components/adminHome"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StudentHome from "../components/studentHome"
import TeacherHome from "../components/teacherHome"
import { userSelector, UserState } from "../hasura/slices/user"

export default function HomePage() {
  const { user, isTeacher, isStudent, isAdmin, isPedagogue }: UserState = useSelector(userSelector)

  return (
    <Layout noStyles={isAdmin || isStudent}>
      <SEO title="Home" />

      {user && isTeacher && <TeacherHome />}

      {user && (isAdmin || isPedagogue) && <AdminHome />}

      {user && isStudent && <StudentHome />}
    </Layout>
  )
}

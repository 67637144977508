import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, Input } from "reactstrap"
import partition from "lodash/partition"
import Select from "react-select"

import { Option } from "../../interfaces/option"
import { conceptSelector, ConceptState } from "../../hasura/slices/concept"
import { curriculumSelector, CurriculumState } from "../../hasura/slices/curriculum"
import { pluralize } from "../../lib/helpers"
import { useSelector } from "react-redux"

interface Props {
  close: () => void
  upload: (concepts: string[], newConcepts: string[], pedagogueId?: string) => void
}

export default function UploadConceptsModal(props: Props) {
  const { concepts }: ConceptState = useSelector(conceptSelector)
  const { pedagogues }: CurriculumState = useSelector(curriculumSelector)

  const [pedagogueId, setPedagogueId] = useState<string | undefined>()

  const pedagogueOptions: Option[] = pedagogues.map((p) => ({ value: p.id, label: p.display_name }))

  const [conceptsToUpload, setConceptsToUpload] = useState<string>("")

  const conceptsToUploadSplit = conceptsToUpload.split("\n").filter((s) => s)

  const [duplicateConcepts, newConcepts] = partition(conceptsToUploadSplit, (c) => concepts?.find((concept) => concept.display_name === c))

  return (
    <Modal size="lg" fade={false} centered isOpen={true} toggle={props.close}>
      <ModalBody className="text-center min-height-200px flex-center flex-column p-4">
        <p className="text-l bold mb-0">Upload/Assign Concepts</p>

        <p style={{ marginTop: "-2px" }} className="text-xs font-italic text--gray7">
          * upload a list of concepts separated by newlines
        </p>

        <Input
          autoFocus
          className="min-height-300px"
          type="textarea"
          value={conceptsToUpload}
          onChange={(e: any) => setConceptsToUpload(e.target.value)}
        />

        <div className="w-100 text-left">
          <p className="text-xs text--gray8 bold mb-1 mt-3">Assign to (optional)</p>
          <Select
            onChange={(option: any) => setPedagogueId(option.value)}
            value={pedagogueOptions.find((p) => p.value === pedagogueId)}
            className="width-200px"
            options={pedagogueOptions}
            placeholder="Pedagogue"
            isClearable
          />
        </div>
      </ModalBody>

      <ModalFooter className="align-items-center flex-column">
        <Button
          className="min-width-200px height-50px flex-center"
          color="success"
          disabled={!conceptsToUploadSplit.length}
          onClick={() => props.upload(conceptsToUploadSplit, newConcepts, pedagogueId)}
          size="lg"
        >
          Upload{conceptsToUploadSplit.length > 0 && ` ${pluralize("concept", conceptsToUploadSplit.length)}`}
        </Button>

        <p onClick={props.close} className="text-link text-muted">
          Cancel
        </p>

        {duplicateConcepts.length > 0 && (
          <p className="text-xs text--gray7 mb-0 mt-1 px-4">
            <span className="bold text-danger">WARNING</span> the following concepts already exists: {duplicateConcepts.sort().join(", ")}
          </p>
        )}
      </ModalFooter>
    </Modal>
  )
}
